/* eslint-disable no-unused-vars */

import { Fragment, useContext, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo from '../images/logo.png'
// import icon from '../images/icon.png'

import { LangContext } from '../context/LangContext';
import { LangChange } from '../hooks/LangChange';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const NavBar = () => {
  const [language, setLanguage] = useContext(LangContext);

  const navigation = [
    { name: language.home, href: '#home', current: false },
    { name: language.about, href: '#about', current: false },    
    // { name: language.product, href: '#product', current: false },
    // { name: language.partner, href: '#partner', current: false },
    // { name: language.benefits, href: '#benefits', current: false },
    // { name: language.investor, href: '#investor', current: false },
    { name: language.contact, href: '#contact', current: false },    
  ]
  const navigationdropdown = [
    { name: language.home, href: '#', current: true },
    { name: language.product, href: '#', current: false },
    { name: language.contact, href: '#', current: false },
    { name: language.login, href: '#', current: false },
  ]
  const [{langs, select }, setlang] = useState(LangChange(language.id))

  const changes = (country) => {
    setLanguage(country)
    setlang(LangChange(country))
  }
  return (
    /**Navbar  */
    <Disclosure as="nav" className="bg-white w-screen fixed   items-center z-50">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">                  
                  {open ? (
                    <XMarkIcon className="block h-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {/* icono de logo */}
              <div className="flex flex-1 items-center justify-center w-screen ml-0 sm:ml-10 md:ml-24">
                {/* <div className="flex flex-shrink-0">
                  <img
                    className="h-6 sm:h-6 lg:7 xl:h-9 w-auto"
                    src={icon}
                    alt="SKYLINK"
                  />
                </div> */}
                {/* Logo principal en nabvar */}
                <div className="flex flex-shrink-0">
                  <img
                    className="h-16 sm:h-16 lg:h-14 xl:h-16 w-auto"
                    src={logo}
                    alt="ZENDY"
                  />
                  
                </div>
                    {/* MAIN MENU */}
                    {/* <div className="   h-16 mr-12 "> */}
                    <div className="hidden w-full sm:flex sm:space-x-2 space-x-16  items-center justify-end">
                    {navigation.map((item) => (
                      <a 
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'border-b-4 border-subtitle text-montbold' : 'text-montbold hover:border-b-4 border-montbold ',
                          'my-6   md:my-4 md:mx-2 md:px-1    text-xs lg:text-xl sm:text-sm font-montbold truncate hover:border-subtitle' 
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                        
                      </a>
                    ))}
                        {/* login button  */} 

                       
                        
                        <a href="https://app.zendy.tel" target='_blank'  rel="noreferrer"
                          className=" text-xs lg:text-xl  sm:text-sm  font-ddin truncate rounded-[32px] mx-0 px-3  bg-subtitle text-white whitespace-nowrap hover:bg-linea">
                            { language.login }
                          </a> 
                          {/* register button  */}
                        <a href="https://transfers.zendy.tel/signup" target='_blank'  rel="noreferrer"
                          className=" text-xs lg:text-xl  sm:text-sm  font-ddin truncate rounded-[32px] mx-0 px-3  bg-subtitle text-white whitespace-nowrap hover:bg-linea">
                          { language.register }
                        </a> 

                  </div>
                {/* </div> */}
              </div>              
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Language  dropdown */}
                <a href="https://play.google.com/store/apps/details?id=com.skylink.zendyapp" ><img className="h-6 sm:h-8 xl:h-10 w-6 sm:w-8  xl:w-10 rounded-full" src="https://cdn-dev.zendy.tel/images/gplay.png" width="25px" height="25px" /></a>
                      <a href="https://apps.apple.com/gb/app/zendy-app/id6477793199" ><img className="h-6 sm:h-8 xl:h-10 w-6 sm:w-8  xl:w-10 rounded-full" src="https://cdn-dev.zendy.tel/images/istore.png" width="25px" height="25px" /></a>
                  
                <Menu as="div" className="relative">
                  <div>
                    {/* image from language */}
                    
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm">    
                      
                      <img
                        className="h-6 sm:h-8 xl:h-10 w-6 sm:w-8  xl:w-10 rounded-full"
                        src={select.img}
                        alt={select.lang}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {langs.map((lang) => (
                            <Menu.Item key={lang.name}>
                            {({ active }) => (
                              <a href="/#" onClick={ () => changes(lang.name) } className="block px-4 py-2 text-sm text-black hover:bg-second dark:text-quina dark:hover:bg-second dark:hover:text-white" role="menuitem">
                              <div className="font-ddin text-xl inline-flex items-center">
                                <img src={lang.img} className="h-7 w-7 rounded-full mr-2" alt={lang.lang}/>
                                {lang.lang}
                              </div>
                            </a>
                            )}
                          </Menu.Item>
                        ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="bg-[#f9c9ca] mb-5 rounded-lg">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-primary text-white' : 'text-quater hover:bg-second hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

