import { useContext } from 'react';
import back from '../images/Section3/back.jpg';
// import cel from '../images/Section3/cel.jpg';
// import ipad from '../images/Section3/ipad.jpg';
import { LangContext } from '../context/LangContext';

import c1 from '../images/Section3/c1.jpg';
import c2 from '../images/Section3/c2.jpg';
import p1 from '../images/Section3/p1.jpg';
// import p2 from '../images/Section3/p2.jpg';


/** ABOUT PAGE */
export const AboutUs = () => {

  const [language] = useContext(LangContext);
  

  return (
    <div className="relative flex flex-col">
      {/* Imagen para pantallas pequeñas */}
      <div className="block lg:hidden">
        <img src={c1} className="object-cover w-full" alt='mobile-background' />
        <div className='px-10 md:px-16 py-6 bg-black bg-opacity-0' style={{
            backgroundImage: `url(${c2})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'overlay', // Esto puede ajustar la mezcla entre la imagen y el color de fondo
            //backgroundColor: 'rgba(0, 0, 0, 0.5)' // Color de fondo negro con opacidad para el contraste
          }}>
          <p className='text-title font-bold text-justify text-lg sm:text-lg md:text-xl lg:text-3xl '>
            {language.secc3text1}
          </p>
          <p className='font-medium text-white text-justify text-sm sm:text-sm md:text-base lg:text-lg mt-4'>
            {language.secc3text2}
          </p>
          <p className='font-medium text-white text-justify text-sm sm:text-sm md:text-base lg:text-lg mt-4'>
            {language.secc3text3}
          </p>
          <div className='flex justify-end mt-4'>
            <button className="font-medium text-white bg-linea rounded-full hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title text-xs md:text-base xl:w-44 md:w-52 sm:w-44 w-32 xl:h-10 md:h-10 sm:h-10 h-8">
              {language.secc5lang1}
            </button>
          </div>
        </div>
      </div>

      {/* Imagen y texto para pantallas grandes */}
      <div className="relative hidden lg:flex flex-col">
        <img src={back} className="object-cover w-full h-screen" alt='desktop-background' />
        <div className='absolute inset-0 flex flex-col justify-center items-start p-10 lg:p-24 text-white'>
          <div className='w-full lg:w-1/2'>
            <p className='text-title font-bold text-left text-lg sm:text-lg md:text-xl lg:text-3xl'>
              {language.secc3text1}
            </p>
            <p className='font-medium text-white text-justify text-sm sm:text-sm md:text-base lg:text-lg mt-4'>
              {language.secc3text2}
            </p>
            <p className='font-medium text-white text-justify text-sm sm:text-sm md:text-base lg:text-lg mt-4'>
              {language.secc3text3}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};