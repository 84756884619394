
import { useContext } from 'react';
import React from 'react';
// import back from '../images/Section1/back01-pc.jpg';
// import backp from '../images/Section1/back01-pad.jpg';
// import backm from '../images/Section1/back01-mov.jpg';

import f1 from '../images/Section1/f1.jpg';
// import f2 from '../images/Section1/f2.jpg';
import p1 from '../images/Section1/p1.jpg';
// import p2 from '../images/Section1/p2.jpg';
import { LangContext } from '../context/LangContext';
//HOME SECTION.
export const Home = () => {
    const [language] = useContext(LangContext);
  return (
    <>    
        <div className="flex flex-col">
            <div className="">
                <img src={f1} className="object-cover w-screen block md:hidden" alt=''/>
                <img src={p1} className="object-cover w-screen hidden md:block lg:hidden" alt=''/>
                
                
                <div className="hidden lg:flex bg-cover bg-center bg-[url('./images/Section1/back.jpg')]">
                    <div className='flex flex-row h-full min-h-screen w-screen'>
                        {/* <div className='w-auto lg:w-1/2 px-10 md:px-16 lg:px-24 bg-red/60'> */}
                        <div className='flex flex-col w-1/2 h-auto justify-evenly pb-5 mt-16'>
                            <div className='w-full ml-24'>
                                <p className='text-title font-montbold text-lg sm:text-lg md:text-xl lg:text-3xl'>{ language.secc1text1 }</p>
                                <p className='text-subtitle text-lg font-montbold sm:text-lg md:text-xl lg:text-3xl'>{ language.secc1text2 }</p>                
                                <p className='text-title my-4 font-montmed text-justify text-sm sm:text-sm md:text-base lg:text-lg'>{language.secc1text3}</p>
                                {/* <p className='text-title my-4 font-montmed text-sm sm:text-sm md:text-base lg:text-lg'>{language.secc1text3.split('.').map((text, index, array) => (
                                    <React.Fragment key={index}>
                                        {text.trim()}
                                        {index === array.length - 2 && <br />}
                                    </React.Fragment>
                                ))}</p> */}

                                <p>
                                    <a href="https://transfers.zendy.tel" target="_blank" rel="noreferrer">
                                    <button className="font-montbold text-white bg-subtitle rounded-[30px] 
                                        hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                                        lg:text-xs md:text-base text-sm xl:w-44 md:w-52 sm:w-44 w-32 xl:h-10 md:h-10 sm:h-10 h-6"
                                        width="176 px" min-width="176px">
                                        {language.transfers}
                                    </button>
                                    </a>
                                </p>
                            {/* </div>
                            <div className='w-full ml-24'> */}
                            <br /><br /><br />
                                <p className='text-title text-lg font-montbold sm:text-lg md:text-xl lg:text-3xl'>{ language.secc1text4 }</p>
                                <p className='text-subtitle text-lg font-montbold sm:text-lg md:text-xl lg:text-3xl'>{ language.secc1text5 }</p>
                                <p className='text-title my-4 font-montmed text-justify sm:text-sm md:text-base lg:text-lg text-sm'>{ language.secc1text6 }</p>
                                <a href="https://app.zendy.tel" rel="noreferrer">
                                <div className='mt-3 inline-flex xl:w-72 lg:w-64 md:w-72 w-52 justify-around mx-auto'>

                                <button className="font-montbold text-white bg-subtitle rounded-[30px] 
                                                hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                                                lg:text-xs md:text-base text-sm xl:w-44 md:w-52 sm:w-44 w-32 xl:h-10 md:h-10 sm:h-10 h-6"
                                                width="176 px" min-width="176 px">
                                    {language.idcalls}
                                    
                                </button>
                                <img  src="https://cdn-dev.zendy.tel/images/Powered-by-T.svg" width="125px" height="25px" />
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>

            <div className="bg-cover bg-center h-full py-10 block lg:hidden
                bg-[url('./images/Section1/f2.jpg')] md:bg-[url('./images/Section1/p2.jpg')]">
                    <div className='w-auto lg:w-1/2 px-10 md:px-16 lg:px-24'>
                        <p className='text-title font-montbold text-lg sm:text-lg md:text-xl lg:text-3xl'>{ language.secc1text1 }</p>
                        <p className='text-subtitle text-lg font-montbold sm:text-lg md:text-xl lg:text-3xl'>{ language.secc1text2 }</p>                
                        <p className='text-title my-4 font-montmed text-justify antialiased text-sm sm:text-sm md:text-base lg:text-lg'>{ language.secc1text3 }</p>
                        <a href="https://transfers.zendy.tel" target="_blank" rel="noreferrer">
                            <button className="font-montmed text-white bg-subtitle rounded-[30px] 
                                hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                                lg:text-xs md:text-base text-sm xl:w-44 md:w-52 sm:w-44 w-32 xl:h-10 md:h-10 sm:h-10 h-6"
                                min-width="176 px">
                                {language.transfers}
                            </button>
                        </a>
                    </div>
                    
                    <div className='mt-0 w-auto lg:w-1/2 px-10 md:px-16 lg:px-24 pt-9'>
                        <p className='text-title text-lg font-montbold sm:text-lg md:text-xl lg:text-3xl'>{ language.secc1text4 }</p>
                        <p className='text-subtitle text-lg font-montbold sm:text-lg md:text-xl lg:text-3xl'>{ language.secc1text5 }</p>
                        <p className='text-title my-4 text-justify font-montmed subpixel-antialiased sm:text-sm md:text-base lg:text-lg text-sm'>{ language.secc1text6 }</p>
                        <a href="https://app.zendy.tel" rel="noreferrer">
                        <div className='mt-3 inline-flex justify-around mx-auto'>
                            <button className="font-montmed text-white bg-subtitle rounded-[30px] 
                                            hover:border-2 hover:border-title hover:bg-opacity-0 hover:text-title
                                            lg:text-xs md:text-base text-sm xl:w-44 md:w-52 sm:w-44 w-32 xl:h-10 md:h-10 sm:h-10 h-6"
                                            min-width="176 px">
                                {language.idcalls}
                            </button>
                            <img  src="https://cdn-dev.zendy.tel/images/Powered-by-T.svg" width="125px" height="25px" />
                        </div>
                        </a>
                    </div>
                    
            </div>
        </div>
 
    </>
  )
}